<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-fw fa-truck"></i> Hersteller</h4>
                        </div>
                        <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                            <ul class="navbar-nav">  
                                <li class="nav-item mr-1">
                                    <div class="input-group input-group-sm" style="margin-top: 0;">
                                        <input type="text" class="form-control" placeholder="Suchen" @keyup.enter="loadProducers" :value="search" @input="search = $event.target.value" />
                                        <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadProducers">
                                            <i class="fas fa-search"></i>
                                        </button>
                                        <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                </li>
                                <li>
                                    <button type="button" class="mr-1 btn btn-default" @click="loadProducers">
                                        <i class="fas fa-sync"></i>
                                    </button>
                                </li>
                                <li>
                                    <button type="button" class="btn btn-primary" @click="createModal" v-if="$auth.check('producers.create')">
                                        <i class="fas fa-plus-circle"></i> Neuer Hersteller
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('number')">Herstellernummer</a>
                                            <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('name')">Name</a>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>
                                            <a href="#" @click.prevent="changeSort('slug')">SEO</a>
                                            <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                            <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'desc'" >&darr;</span>
                                        </th>
                                        <th>Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="producer in producers.data" :key="producer.id" @dblclick="onRowDoubleClick(producer)">
                                        <td>{{producer.number}}</td>
                                        <td>{{producer.name}}</td>
                                        <td>{{producer.slug}}</td>
                                        <td>
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'producers.show', params: {id: producer.id}}" v-if="$auth.check('producers.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'producers.details', params: {id: producer.id}}" v-if="$auth.check('producers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteProducer(producer.id)" variant="danger" v-if="$auth.check('producers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer" v-if="notEmptyObject(producers)">
                        <template v-if="producers.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="producers" @pagination-change-page="loadProducers" :limit="3"></pagination>
                                </div>
                                <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ producers.meta.from }} - {{ producers.meta.to }} von {{ producers.meta.total }}</span>
                                </div>
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Artikel</option>
                                        <option value="50">50 Artikel</option>
                                        <option value="75">75 Artikel</option>
                                        <option value="100">100 Artikel</option>
                                        <option value="125">125 Artikel</option>
                                        <option value="150">150 Artikel</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="producerModal" title="Neuen Hersteller anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                    <!-- <div class="row">
                        <div class="col-md-8">
                            <div class="form-group">
                                <label for="company_id" class="control-label">Shop*</label>
                                <select v-model="form.company_id" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('company_id')}">
                                    <option v-for="company in companies" :key="company.id" :value="company.id">{{company.name}}</option>
                                </select>
                                <has-error :form="form" field="company_id"></has-error>
                            </div>
                        </div>
                    
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="active" class="control-label">Online anzeigen?*</label>
                                <select v-model="form.active" type="text" class="form-control" :class="{'is-invalid': form.errors.has('active')}">
                                    <option value="1">Ja</option>
                                    <option value="0">Nein</option>
                                </select>
                                <has-error :form="form" field="active"></has-error>
                            </div>
                        </div>

                    </div> -->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="name" class="control-label">Name*</label>
                                <input v-model="form.name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('name')}">
                                <has-error :form="form" field="name"></has-error>
                            </div>

                            <div class="form-group" style="color: #499360;">
                                <label for="display_name" class="control-label">
                                    Anzeige-Name
                                </label>
                                <i class="fas fa-question-circle" id="tooltip-displayname"></i>
                                <b-tooltip target="tooltip-displayname" triggers="hover">
                                    Wenn ein Name in das Feld eingetragen wird, wird dieser in der Front auf Produktebene angezeigt (Artikelübersicht, Artikeldetailansicht...). 
                                    In der Herstellerübersicht wird der richtige Name angezeigt. Das Feld kann also auch leer bleiben.
                                </b-tooltip>  
                                <input v-model="form.display_name" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('display_name')}">
                                <has-error :form="form" field="display_name"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="number" class="control-label">Herstellernummer*</label>
                                <input v-model="form.number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('number')}">
                                <has-error :form="form" field="number"></has-error>
                            </div>

                            <div class="form-group">
                                <label for="description" class="control-label">Beschreibung</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                <has-error :form="form" field="description"></has-error>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label for="street" class="control-label">Straße*</label>
                                            <input v-model="form.street" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('street')}">
                                            <has-error :form="form" field="street"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="house_number" class="control-label">Nr.*</label>
                                            <input v-model="form.house_number" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                            <has-error :form="form" field="house_number"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="zipcode" class="control-label">PLZ*</label>
                                        <input v-model="form.zipcode" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                        <has-error :form="form" field="zipcode"></has-error>
                                    </div>

                                    <div class="col-md-8">
                                        <label for="city" class="control-label">Ort*</label>
                                        <input v-model="form.city" type="text" class="form-control form-cotrol-sm" :class="{'is-invalid': form.errors.has('city')}">
                                        <has-error :form="form" field="city"></has-error>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="logo" class="control-label">Logo</label>
                                <!-- <input v-model="form.logo" type="file" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('logo')}"> -->
                                <b-form-file id="logo" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                                <has-error :form="form" field="logo"></has-error>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

    </b-container>
</template>

<script>
import ClassicEditor from '../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: "ProducersIndex",
    title: "Hersteller",
    data() {
        return {
            form: new window.UploadForm({
                id: "",
                // company_id: "",
                name: "",
                number: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                // active: "1",
                logo: [],
            }),
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            sortBy: "id",
            sortDesc: false,
            params: {
                sort_field: 'number',
                sort_direction: 'desc',
                producer_id: '',
                tax_id: '',
                company_id: '', 
                is_active: '',
                per_page: 25,
                group_id: '',
                options: '',
                start_date: '',
                stop_date: '',
                start_compare_date: '',
                stop_compare_date: ''
            },
            search: '',
            page: 1,
            producers: {},
            companies: [],
        }
    },
    watch: {
        params: {
            handler () {
                this.loadProducers();
            },
            deep: true
        },
    },
    methods: {
        onRowDoubleClick(producer){
            this.$router.push({name: 'producers.details', params: {id: producer.id}})
        },
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        resetSearch(){
            this.search = '';
            this.loadProducers();
        },
        deleteSelectedProducers(){
            this.$swal({
                    title: "Möchtest du die selektierten Hersteller wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/producers/deleteMany", {data: this.selectedProducers})
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Hersteller erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadProducers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createProducer();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.$bvModal.show("producerModal");
        },

        deleteProducer(id) {
            this.$swal({
                    title: "Möchtest du den Hersteller wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/producers/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Hersteller erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadProducers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createProducer() {
            this.$Progress.start();
            this.form
                .post("/producers")
                .then(() => {
                    this.$bvModal.hide("producerModal");
                    this.$swal({
                        icon: "success",
                        title: "Hersteller wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadProducers();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },


        loadProducers(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.$Progress.start();
            this.axios
                .get("/producers", {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.producers = response.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCompanies() {
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadProducers();
        this.loadCompanies();
    },



}
</script>

<style>

</style>